// Layout.js
const Layout = ({ children }) => {
    return (
      <div className="min-h-screen bg-gray-900 text-white py-12 px-4">
        {children}
      </div>
    );
  };
  
  export default Layout;
  