import React, { useState } from "react";

const Navbar = () => {
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const toggleMobileMenu = () => setIsMobileOpen(!isMobileOpen);

  return (
    <nav className="bg-gray-900 text-white shadow-md sticky top-0 w-full z-10">
      <div className="max-w-screen-xl mx-auto px-4 py-2 flex justify-center items-center">
        {/* Desktop Menu */}
        <div className="hidden md:flex space-x-8">
          <a
            href="/"
            className="text-2xl font-bold hover:text-gray-400 transition duration-300"
          >
            Home
          </a>
          <a
            href="/projects"
            className="text-2xl font-bold hover:text-gray-400 transition duration-300"
          >
            Projects
          </a>
          <a
            href="/resume"
            className="text-2xl font-bold hover:text-gray-400 transition duration-300"
          >
            Resume
          </a>
          <a
            href="/contact"
            className="text-2xl font-bold hover:text-gray-400 transition duration-300"
          >
            Contact
          </a>
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden ml-4">
          <button onClick={toggleMobileMenu} className="text-white focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isMobileOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu Dropdown */}
      {isMobileOpen && (
        <div className="md:hidden px-4 py-2 space-y-4 bg-gray-800">
          <a
            href="/"
            className="block text-2xl font-bold text-white hover:text-gray-400 transition duration-300"
          >
            Home
          </a>
          <a
            href="/projects"
            className="block text-2xl font-bold text-white hover:text-gray-400 transition duration-300"
          >
            Projects
          </a>
          <a
            href="/resume"
            className="block text-2xl font-bold text-white hover:text-gray-400 transition duration-300"
          >
            Resume
          </a>
          <a
            href="/contact"
            className="block text-2xl font-bold text-white hover:text-gray-400 transition duration-300"
          >
            Contact
          </a>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
